import React, { useContext, useEffect, useState } from "react";
import qs from "qs";
import { GlobalContextState } from "../../context/globalContext";
import { baseUrl } from "../../config";

export default function TokenHistory() {
  const user = useContext(GlobalContextState).user;
  const tokenUrl = `${baseUrl}/api/token-histories`;
  const token = useContext(GlobalContextState).token;
  const [tokenHistory, setTokenHistory] = useState([]);
  
  useEffect(() => {
    const initFetchData = async () => {
        await fetchTokenHistoryData();
    };

    initFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchTokenHistoryData() {
    try {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);
      
      const query = qs.stringify({
        filters: {
          users: {
            username: {
              $eq: user.username,
            },
          },
        },
        sort: ['changedAt:desc'],
        pagination: {
          limit: 300,
        },
      }, {
        encodeValuesOnly: true,
      });

      const fetchUrl = `${tokenUrl}?${query}`;
      const response = await fetch(fetchUrl, {
        method: 'GET',
        headers: myHeaders,
      });

      if (response.ok) {
        const data = await response.json();
        setTokenHistory(data.data);
      }
    }
    catch (error) {
      console.log('Error:', error);
    }
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZone: 'America/New_York'
    }).format(date);
  };

  function DescriptionCell({ item }) {
    const changeDescription = item.attributes.changeDescription;
    const claimIdPattern = /Claim ID: ([\w-]+)/g;
    const updatedDescription = changeDescription.replace(claimIdPattern, (match, claimID) => {
      return `<a class="underline text-indigo-400" href="/claims/claim-details/${claimID}" target="_blank">${claimID}</a>`;
    });
  
    return (
      <td className="border border-gray-700 border-solid px-6 py-4 whitespace-nowrap text-sm text-gray-400" dangerouslySetInnerHTML={{ __html: updatedDescription }} />
    );
  }


  return (
    <>
      <div className="px-14 flex justify-between items-center pt-8">
        <div>
          <h2 className="text-2xl font-bold text-white text-3xl">Account Statement</h2>
        </div>
      </div>
      <div className="px-14 py-4">
          <div className="rounded bg-gray-800 p-4 mb-6 text-white">
            <table className="min-w-full divide-y divide-gray-700 border border-gray-700 border-solid">
              <thead className="bg-gray-800">
                <tr>
                  <th scope="col" className="border border-gray-700 border-solid px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-300">
                    Service
                  </th>
                  <th scope="col" className="border border-gray-700 border-solid px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-300">
                    Description
                  </th>
                  <th scope="col" className="border border-gray-700 border-solid px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-300">
                    Amount
                  </th>
                  <th scope="col" className="border border-gray-700 border-solid px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-300">
                    Balance
                  </th>
                  <th scope="col" className="border border-gray-700 border-solid px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-300">
                    Time
                  </th>
                </tr>
              </thead>
              <tbody className="bg-gray-800 divide-y divide-gray-700">
                {tokenHistory.map((item, index) => (
                  <tr key={index}>
                    <td className="border border-gray-700 border-solid px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-300">{item.attributes.changeTitle}</td>
                    <DescriptionCell item={item} />
                    <td
                      className={`border border-gray-700 border-solid px-6 py-4 text-sm font-medium ${
                        item.attributes.change < 0 ? 'text-red-500' : 'text-green-500'
                      }`}
                    >
                      {item.attributes.change}
                    </td>
                    <td className="border border-gray-700 border-solid px-6 py-4 whitespace-nowrap text-sm text-gray-400">{item.attributes.currentBalance ? item.attributes.currentBalance : '-'}</td>
                    <td className="border border-gray-700 border-solid px-6 py-4 text-sm text-gray-400">{formatDate(item.attributes.changedAt)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
      </div>
    </>
  );
}
